html {
    text-align: center;
}

body {
    font-family: -apple-system, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    display: inline-block;
    margin: 0;
    max-width: 800px;
    text-align: left;
    width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    border: none;
    color: #828282;
    text-decoration: none;
}

a:hover {
    color: #3c4146;
}

button {
    background-color: #f0f0f0;
    border: 1px solid #fff;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    color: #3c4146;
    cursor: pointer;
    margin: 0 5px;
    padding: 10px;
    text-align: center;
    font-size: 20px;
}

button:hover:not([disabled]) {
    background-color: #828282;
    border: 1px solid #f0f0f0;
    color: #fff;
}

button[disabled] {
    color: #828282;
    cursor: not-allowed;
}

input, textarea {
    border: 1px solid #828282;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin: 15px 5px 10px 5px;
    padding: 5px;
    display: block;
    width: 100%;
    font-size: 20px;
}

input[disabled], textarea[disabled] {
    color: #3c4146;
}

form {
    margin-left: -5px;
}

/* ------ APP.JS ------ */

.App {
  font-size: calc(10px + 1vmin);
}

.App-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-logo {
    max-width: 95%;
}

.main-content {
    margin: 35px 50px 50px 50px;
}

/* ------ NAVIGATION ------ */

nav {
    margin: 25px 0 35px 0;
    padding: 0;
    text-align: center;
}

nav ul {
    margin: 0;
    padding: 0;
}

nav li {
    display: inline-block;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    width: calc(100% / 5);
}

nav a {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    color: #828282;
    font-weight: 400;
    font-size: calc(11px + 1vmin);
    font-style: normal;
    padding: 5px;
}

nav button {
    background-color: #ffffff;
    border: none;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    color: #828282;
    cursor: pointer;
    font-weight: 400;
    font-size: calc(11px + 1vmin);
    font-style: normal;
    padding: 5px;
}

nav button:hover {
    background-color: #fff;
    border: none;
}

/* ------ ORGANIZATIONS AND ORG REQUESTS ------ */

.org-list li,
.org-request-list li {
    list-style-type: none;
    margin-left: -40px;
}

.org-request {
    background-color: #f0f0f0;
    border: 1px solid #3c4146;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}

.org-request div {
    padding: 5px;
}

.org-request button {
    background-color: #fff;
    margin-top: 10px;
}

.org {
    background-color: #f0f0f0;
    border: 1px solid #3c4146;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 50px;
    padding: 0;
}

.org div {
    display: inline-block;
    margin: 0;
    padding: 30px 20px;
}

.org img {
    margin-right: -10px;
    max-height: 60px;
    max-width: 60px;
    padding-left: 20px;
    vertical-align: middle;
}

.org a {
    display: block;
    padding: 30px 20px;
}

.org a:hover {
    background-color: #828282;
    color: #fff;
}

.stripe-status {
    border-left: 1px solid #3c4146;
    display: block;
}

.no-padding {
    padding: 0 !important;
}

.hidden {
    visibility: hidden;
}

.loading-state .loading-icon {
    position:relative;
    display: block;
}

.loading-state img {
    position: absolute;
    height: 40px;
    transform: translate(-50%, -8px);
}

.page-load {
    text-align: center;
}

.page-load span {
    display: block;
    margin-top: 15px;
}

.modal {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 25%);
    text-align: center;
    margin-bottom: 25px;
}

.modal label {
    margin-top: 15px;
}

.modal button {
    margin-top: 25px;
}

/* ------ TRANSITIONS ------ */

a,
button:not([disabled]) {
    -webkit-transition: all 0.5s ease-in;
    -moz-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;

    -webkit-transition-property: color, background-color, border-color;
    -moz-transition-property: color, background-color, border-color;
    -o-transition-property: color, background-color, border-color;
    transition-property: color, background-color, border-color;
}

a:hover,
button:hover:not([disabled]) {
    -webkit-transition: all 0s ease-in;
    -moz-transition: all 0s ease-in;
    -o-transition: all 0s ease-in;
    transition: all 0s ease-in;

    -webkit-transition-property: color, background-color, border-color;
    -moz-transition-property: color, background-color, border-color;
    -o-transition-property: color, background-color, border-color;
    transition-property: color, background-color, border-color;
}

/* ------ COLORS ------ */

/* Light Blue #5F8FFF */
p:nth-of-type(5n+1) a:nth-of-type(5n+1):hover,
p:nth-of-type(5n+2) a:nth-of-type(5n+2):hover,
p:nth-of-type(5n+3) a:nth-of-type(5n+3):hover,
p:nth-of-type(5n+4) a:nth-of-type(5n+4):hover,
p:nth-of-type(5n+5) a:nth-of-type(5n+5):hover {
    color: #5F8FFF;
}

form:nth-of-type(1) button:hover:not([disabled]),
nav li:nth-of-type(1) a:hover,
.org-list li:nth-of-type(1) a:hover {
    background-color: #5F8FFF;
    color: #fff;
}

.input:nth-child(1),
.org-request:nth-of-type(5n+1),
form:nth-of-type(1) button {
    border-color: #5F8FFF;
}

/* Blue #4652D7 */
p:nth-of-type(5n+1) a:nth-of-type(5n+5):hover,
p:nth-of-type(5n+2) a:nth-of-type(5n+1):hover,
p:nth-of-type(5n+3) a:nth-of-type(5n+2):hover,
p:nth-of-type(5n+4) a:nth-of-type(5n+3):hover,
p:nth-of-type(5n+5) a:nth-of-type(5n+4):hover {
    color: #4652D7;
}

form:nth-of-type(2) button:hover:not([disabled]),
nav li:nth-of-type(2) a:hover,
.org-list li:nth-of-type(2) a:hover {
    background-color: #4652D7;
    color: #fff;
}

.input:nth-child(2),
.org-request:nth-of-type(5n+2) {
    border-color: #4652D7;
}

/* Orange #FF5226 */
p:nth-of-type(5n+1) a:nth-of-type(5n+4):hover,
p:nth-of-type(5n+2) a:nth-of-type(5n+5):hover,
p:nth-of-type(5n+3) a:nth-of-type(5n+1):hover,
p:nth-of-type(5n+4) a:nth-of-type(5n+2):hover,
p:nth-of-type(5n+5) a:nth-of-type(5n+3):hover {
    color: #FF5226;
}

form:nth-of-type(3) button:hover:not([disabled]),
nav li:nth-of-type(3) a:hover,
.org-list li:nth-of-type(3) a:hover {
    background-color: #FF5226;
    color: #fff;
}

.input:nth-child(3),
.org-request:nth-of-type(5n+3) {
    border-color: #FF5226;
}

/* Red #BD3326 */
p:nth-of-type(5n+1) a:nth-of-type(5n+3):hover,
p:nth-of-type(5n+2) a:nth-of-type(5n+4):hover,
p:nth-of-type(5n+3) a:nth-of-type(5n+5):hover,
p:nth-of-type(5n+4) a:nth-of-type(5n+1):hover,
p:nth-of-type(5n+5) a:nth-of-type(5n+2):hover {
    color: #BD3326;
}

form:nth-of-type(4) button:hover:not([disabled]),
nav li:nth-of-type(4) a:hover,
.org-list li:nth-of-type(4) a:hover {
    background-color: #BD3326;
    color: #fff;
}

.input:nth-child(4),
.org-request:nth-of-type(5n+4) {
    border-color: #BD3326;
}

/* Yellow #FFD51C */
p:nth-of-type(5n+1) a:nth-of-type(5n+2):hover,
p:nth-of-type(5n+2) a:nth-of-type(5n+3):hover,
p:nth-of-type(5n+3) a:nth-of-type(5n+4):hover,
p:nth-of-type(5n+4) a:nth-of-type(5n+5):hover,
p:nth-of-type(5n+5) a:nth-of-type(5n+1):hover {
    color: #FFD51C;
}

form:nth-of-type(5) button:hover:not([disabled]),
nav button:hover,
nav li:nth-of-type(5) a:hover,
.org-list li:nth-of-type(5) a:hover {
    background-color: #FFD51C;
    color: #fff;
}

.input:nth-child(5),
.org-request:nth-of-type(5n+5) {
    border-color: #FFD51C;
}
